import { Box, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { ReactComponent as CompanyLogo } from '@/assets/logo_icon_and_text.svg';

const useStyles = makeStyles({
  icon: {
    textAlign: 'center',
    height: '100%',
    width: 110,
  },
  link: {
    textDecoration: 'none',
    color: 'gray',
    fontSize: 13,
  },
});

const Footer = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Box textAlign="center" mt={2.5} mb={3}>
      <Grid>
        <Grid item>
          <a href="https://timelab.jp/" rel="noopener noreferrer" target="_blank" className={classes.link}>
            <CompanyLogo className={classes.icon} />
          </a>
        </Grid>
        <Grid item>
          <a
            href="https://forms.gle/NjQo4Zs25LDQ1pVJ7"
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            ご意見・ご要望はこちらから
          </a>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
