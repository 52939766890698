import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Default from '@/components/layouts/Default';
import { CssBaseline } from '@material-ui/core';

const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <div>
        <CssBaseline />
        <Switch>
          <Route path="/" component={Default} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default App;
