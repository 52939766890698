import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { Backdrop, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import ScheduleDisplay from '@/components/molecules/ScheduleDisplay';
import { DEFAULT_BACKGROUND_COLOR_CODE, DEFAULT_FONT_COLOR_CODE } from '@/constants/index';
import DisplayAlert from '@/components/molecules/DisplayAlert';
import { getScheduleById } from '@/repositories/schedule';
import { copyToClipboard } from '@/utils/index';

const Shared: React.FC<RouteComponentProps<{ uid: string }>> = (props): JSX.Element => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const uid = props.match.params.uid;
  const [msg, setMsg] = useState<string>('');

  // value get from firestore
  const [title, setTitle] = useState('');
  const [preview, setPreview] = useState('');
  const [fontColor, setFontColor] = useState<string>(DEFAULT_FONT_COLOR_CODE);
  const [bgColor, setBgColor] = useState<string>(DEFAULT_BACKGROUND_COLOR_CODE);
  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    (async () => {
      const schedule = await getScheduleById(uid);

      if (schedule) {
        // firestoreから取得したデータをstateに格納
        setTitle(schedule.title);
        setPreview(schedule.preview);
        setFontColor(schedule.fontColor);
        setBgColor(schedule.bgColor);
        if (schedule.downloadImageUrl) await setImageUrl(schedule.downloadImageUrl);

        setIsLoading(false);
      } else {
        // メモリリーク防止のため、ページジャンプする前にfalseに設定
        setIsLoading(false);
        alert('スケジュール情報が存在しません。');
        history.push('/');
      }
    })();
  }, [history, uid]);

  // 'コピー'ボタンをクリックした際の処理
  const handleCopyClick = () => {
    const copyText = `【タイトル】\n${title}\n【スケジュール】\n${preview}`;
    copyToClipboard(copyText);
    setMsg('テキストをコピーしました。');
  };

  // '使ってみる'ボタンをクリックした際の処理
  const handleToTop = () => {
    history.push('/');
  };

  // '編集する'ボタンをクリックした際の処理
  const handleEditClick = () => {
    history.push('/edit/challenge');
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 100, color: '#fff' }}>
        <CircularProgress />
      </Backdrop>
      {/* Alert */}
      <DisplayAlert message={msg} setMessage={setMsg} theme="success" />
      <Box pt={2}>
        <Box display="flex" justifyContent="flex-end" pt={1}>
          <Button variant="outlined" color="secondary" size="small" onClick={handleEditClick}>
            編集する
          </Button>
        </Box>
        <Box pt={1}>
          <ScheduleDisplay
            title={title}
            preview={preview}
            fontColor={fontColor}
            bgColor={bgColor}
            imageUrl={imageUrl}
          />
          <Box display="flex" pt={2} justifyContent="center">
            <Button variant="contained" color="primary" size="large" onClick={handleCopyClick}>
              <Box mx={3}>
                テキスト
                <br />
                コピー
              </Box>
            </Button>
          </Box>
          <Box py={4}>
            <Typography color="textSecondary" align="center" variant="body2">
              シェアスケジュールは
              <br />
              イベント日程やタイムスケジュールを
              <br />
              かんたんに作成してシェアできるサービスです。
            </Typography>
          </Box>
          <Box display="flex">
            <Button variant="contained" color="secondary" fullWidth size="large" onClick={handleToTop}>
              無料でシェアスケジュールを使ってみる
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Shared;
