import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, createStyles, List, ListItem, makeStyles, TextField, Theme } from '@material-ui/core';
import { DAY_OF_WEEK_ARRAY, SCHEDULE_PLACEHOLDER, SELECTABLE_TIME_DATE, TITLE_PLACEHOLDER } from '@/constants/index';
import { getUsedBrowserName, isMobile } from '@/utils/index';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

type ScheduleFormProps = {
  title: string;
  preview: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  setPreview: React.Dispatch<React.SetStateAction<string>>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputFontResize: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    timeList: {
      overflowX: 'scroll',
      display: 'flex',
      flexDirection: 'row',
    },
    opButton: {
      width: 120,
    },
  })
);

const now = new Date();

const ScheduleForm = ({ title, preview, setTitle, setPreview }: ScheduleFormProps): JSX.Element => {
  const classes = useStyles();
  const previewRef = useRef<HTMLInputElement>(null!);
  const [date, setDate] = useState<Date | null>(now);
  const listRef = useRef<HTMLUListElement>(null!);

  useEffect(() => {
    // 10時の要素を取得
    const tenCardEl = document.getElementById('list-19');
    // 10時の要素の位置情報を取得
    const tenCardPosition = tenCardEl?.getBoundingClientRect();
    // 10時の要素が表示されるようにスクロール位置を調整
    if (tenCardPosition) {
      listRef.current.scrollTo(Number(tenCardPosition.left), 0);
    }
  }, []);

  // カレンダーをクリックした際の処理
  const handleCalendarClick = async (date: MaterialUiPickersDate) => {
    if (date) {
      // 月日・曜日を文字列に変換
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // 2桁になるように調整
      const day = ('0' + date.getDate()).slice(-2); // 2桁になるように調整
      const dayOfWeek = DAY_OF_WEEK_ARRAY[date.getDay()];
      const dateString = `${month}/${day} (${dayOfWeek}) `;
      setDate(date);

      // カーソル行に値を挿入する
      const lines = preview.split('\n');
      const cursorPosition = Number(previewRef.current.selectionStart);
      const cursorLineIndex = findCursorLineIndex(lines, cursorPosition);
      const cursorLineString = lines[cursorLineIndex];

      // 挿入箇所より前の値が空白（改行を含む）だけの場合は改行を末尾につけない
      const insertedString = !/\s/g.test(cursorLineString) ? dateString : `\n${dateString}`;
      const beforeString = preview.slice(0, cursorPosition);
      const afterString = preview.slice(cursorPosition);
      const newScheduleString = beforeString + insertedString + afterString;
      await setPreview(newScheduleString);

      // setPreviewでカーソルが文章全体の末尾に移動するので、元の位置+挿入文字分の位置に移動
      previewRef.current.setSelectionRange(
        cursorPosition + insertedString.length,
        cursorPosition + insertedString.length
      );

      switchCursorFocusByDevise();
    }
  };

  // 時間をクリックした際の処理
  const handleTimeClick = async (time: string) => {
    const lines = preview.split('\n');
    const cursorPosition = Number(previewRef.current.selectionStart);
    const cursorLineIndex = findCursorLineIndex(lines, cursorPosition);
    const cursorLineString = lines[cursorLineIndex];

    // カーソルの１行を解析し、その状態に応じて差し込む文字列の組み立てを変える
    // 1回目の時間は "~"をつける。2回目以降は "~"をつけない。
    const insertedString = /[0-9]{2}:[0-9]{2}/.test(cursorLineString) ? ` ${time}` : `${time} ~`;
    const beforeString = preview.slice(0, cursorPosition);
    const afterString = preview.slice(cursorPosition);
    const newScheduleString = beforeString + insertedString + afterString;

    await setPreview(newScheduleString);

    previewRef.current.setSelectionRange(
      cursorPosition + insertedString.length,
      cursorPosition + insertedString.length
    );

    switchCursorFocusByDevise();
  };

  // '全て削除'ボタンをクリックした際の処理
  const handleResetClick = () => {
    setTitle('');
    setPreview('');
  };

  // '改行'ボタンをクリックした際の処理
  const handleNewLineClick = async () => {
    const cursorPosition = Number(previewRef.current.selectionStart);

    const beforeString = preview.slice(0, cursorPosition);
    const afterString = preview.slice(cursorPosition);
    const newScheduleString = beforeString + `\n` + afterString;

    await setPreview(newScheduleString);

    previewRef.current.setSelectionRange(cursorPosition + 1, cursorPosition + 1);

    switchCursorFocusByDevise();
  };

  // previewのカーソルがある位置のインデックス（行）を調べる
  const findCursorLineIndex = (lines: Array<string>, cursorPosition: number): number => {
    // 各行の先頭から末尾までの位置を取得（空文字の場合は1）
    const lineEndPositionsArr: Array<number> = lines.map((line) => line.length + 1);
    // 何行目かを表すインデックス
    let index = 0;
    // 文全体の先頭から末尾までの位置を取得
    let loopPosition = 0;

    for (index; index <= lineEndPositionsArr.length; index++) {
      loopPosition += lineEndPositionsArr[index];
      // カーソルの位置が現在の位置よりも後にある場合ループを抜ける
      if (cursorPosition < loopPosition) {
        break;
      }
    }
    return index;
  };

  const switchCursorFocusByDevise = () => {
    previewRef.current.focus();
    // スマホ・タブレット（iOS, Android OS）の場合はfocusしたのちblurする
    if (isMobile()) {
      previewRef.current.blur();
    }
  };

  return (
    <Card>
      <Box py={2} px={2}>
        <Box pb={2}>
          <TextField
            size="small"
            fullWidth
            name="title"
            label="タイトル"
            placeholder={TITLE_PLACEHOLDER}
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ className: classes.inputFontResize }}
          />
        </Box>
        <Box>
          <TextField
            size="small"
            fullWidth
            name="preview"
            label="スケジュール"
            variant="outlined"
            placeholder={getUsedBrowserName() === 'Safari' ? '' : SCHEDULE_PLACEHOLDER}
            multiline
            rows={7}
            inputRef={previewRef}
            value={preview}
            onChange={(e) => setPreview(e.target.value)}
            InputLabelProps={{ shrink: true }}
            inputProps={{ className: classes.inputFontResize }}
          />
        </Box>
        <Box>
          <Box display="flex" justifyContent="center">
            <DatePicker
              variant="static"
              name="date"
              disableToolbar
              format="MM/dd"
              margin="normal"
              label="Date"
              value={date}
              onChange={handleCalendarClick}
            />
          </Box>
          <Box width="100%">
            <List ref={listRef} className={classes.timeList}>
              {SELECTABLE_TIME_DATE.map((time, index) => (
                <ListItem key={index} id={`list-${index}`} disableGutters>
                  <Card variant="outlined" onClick={() => handleTimeClick(time)}>
                    <Box py={0.5} px={1}>
                      {time}
                    </Box>
                  </Card>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box display="flex" justifyContent="space-evenly" pt={1} mt={1}>
            <Button variant="contained" color="secondary" onClick={handleResetClick} className={classes.opButton}>
              リセット
            </Button>
            <Button variant="contained" color="primary" onClick={handleNewLineClick} className={classes.opButton}>
              改行
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default ScheduleForm;
