import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { Backdrop, Box, Button, CircularProgress } from '@material-ui/core';
import ScheduleForm from '@/components/molecules/ScheduleForm';
import SnackbarAlert from '@/components/molecules/SnackbarAlert';
import { getScheduleByPassword } from '@/repositories/schedule';

interface RouteProps {
  referer: string;
}

// TODO: ほとんどHome.tsxと同じなので共通化する。
const Edit = (props: RouteComponentProps<{ uid: string }, StaticContext, RouteProps>): JSX.Element => {
  const routeProps = props.location.state;
  const uid = props.match.params.uid;

  const history = useHistory();
  const [title, setTitle] = useState<string>('');
  const [preview, setPreview] = useState<string>('');
  const [docId, setDocId] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    // /edit/challenge 以外からのアクセスを弾く
    if (!routeProps?.referer || routeProps.referer !== '/edit/challenge') {
      alert('不正なリクエストです。');
      history.push('/');
      return;
    }

    (async () => {
      const schedule = await getScheduleByPassword(uid);

      if (schedule) {
        // firestoreから取得したデータをstateに格納
        setDocId(schedule.id);
        setTitle(schedule.title);
        setPreview(schedule.preview);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        alert('スケジュール情報が存在しません。');
        history.push('/');
      }
    })();
  }, [routeProps, uid, history]);

  const handleEditClick = () => {
    if (preview === '') return setOpen(true);
    history.push({
      pathname: '/preview',
      state: {
        title,
        preview,
        docId,
      },
    });
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 100, color: '#fff' }}>
        <CircularProgress />
      </Backdrop>
      <SnackbarAlert open={open} setOpen={setOpen} message="スケジュールを入力してください" theme="error" />
      <Box pt={2}>
        <Box>
          <ScheduleForm title={title} preview={preview} setTitle={setTitle} setPreview={setPreview} />
        </Box>
        <Box pt={1}>
          <Button variant="contained" color="primary" fullWidth size="large" onClick={handleEditClick}>
            変更
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Edit;
