import React, { useState } from 'react';
import { Box, Button } from '@material-ui/core';
import ScheduleForm from '@/components/molecules/ScheduleForm';
import { useHistory } from 'react-router-dom';
import SnackbarAlert from '@/components/molecules/SnackbarAlert';

const Home = (): JSX.Element => {
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [preview, setPreview] = useState('');
  const [open, setOpen] = useState<boolean>(false);

  const handleSaveClick = () => {
    if (preview === '') return setOpen(true);
    history.push({
      pathname: '/preview',
      state: {
        title,
        preview,
      },
    });
  };

  return (
    <>
      <SnackbarAlert open={open} setOpen={setOpen} message="スケジュールを入力してください" theme="error" />
      <Box pt={2}>
        <Box>
          <Box py={1}>
            <ScheduleForm title={title} preview={preview} setTitle={setTitle} setPreview={setPreview} />
          </Box>
          <Box pt={1}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={handleSaveClick}>
              保存
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
