export const TITLE_PLACEHOLDER = 'タイムスケジュール';
export const SCHEDULE_PLACEHOLDER = `6/21(月) 10:00〜11:00 イベント
6/22(火) 12:00〜13:00 イベント
6/24(木) 14:00〜15:00 イベント
6/25(金) 10:00〜11:00 イベント
`;
export const DAY_OF_WEEK_ARRAY: string[] = ['日', '月', '火', '水', '木', '金', '土'];
export const SELECTABLE_TIME_DATE: string[] = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];
export const DEFAULT_BACKGROUND_COLOR_CODE = '#FFF';
export const DEFAULT_FONT_COLOR_CODE = '#000';
export const PALLET_COLORS: string[] = [
  '#000000',
  '#FFFFFF',
  '#ABB8C3',
  '#FF6900',
  '#FCB900',
  '#7BDCB5',
  '#00D084',
  '#8ED1FC',
  '#0693E3',
  '#EB144C',
  '#F78DA7',
  '#9900EF',
];
