import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import DisplayAlert from '@/components/molecules/DisplayAlert';
import { getScheduleByPassword } from '@/repositories/schedule';

// INFO: 編集画面に移行するためにパスワードを入力するページ
const Challenge = (): JSX.Element => {
  const history = useHistory();
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleClickSend = async () => {
    if (password === '') return setError('パスワードを入力してください。');

    setIsLoading(true);

    const schedule = await getScheduleByPassword(password);
    if (schedule) {
      setError('');
      // 編集画面へPushする
      // INFO: paramにはpasswordをセットし、遷移間の整合性を保つためrefererを付与
      //  @see https://qiita.com/KezzyTak/items/5a9f500cdd19df88056c
      history.push({
        pathname: `/edit/${schedule.editPassword}`,
        state: {
          referer: '/edit/challenge',
        },
      });
    } else {
      setIsLoading(false);
      return setError('データが見つかりません。\r\nパスワードを確認してください。');
    }
  };

  return (
    <>
      {/* Loading layer */}
      <Backdrop open={isLoading} style={{ zIndex: 100, color: '#fff' }}>
        <CircularProgress />
      </Backdrop>
      {/* Error alert */}
      <DisplayAlert message={error} setMessage={setError} theme="error" />
      <Box pt={5} px={1}>
        {/* Title */}
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            編集用パスワードを入力してください
          </Typography>
        </Box>
        {/* Form */}
        <Box py={2}>
          <OutlinedInput
            fullWidth
            name="title"
            type={showPassword ? 'text' : 'password'}
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Box pt={2}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={handleClickSend}>
              送信
            </Button>
          </Box>
        </Box>
        {/* Comments */}
        <Box pt={1.5}>
          <Typography variant="body2" color="textSecondary">
            パスワードは再発行できません。
            <br />
            パスワードをお忘れの場合は、お手数ですが
            <br />
            <Link to="/" style={{ textDecoration: 'none', color: '#4A24FF' }}>
              こちら
            </Link>
            からスケジュールを再作成してください。
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Challenge;
