import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';

type SnackbarAlertTypes = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  message: string;
  theme: Color;
};

const SnackbarAlert = (props: SnackbarAlertTypes): JSX.Element => {
  const { open, setOpen, message, theme } = props;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert onClose={handleClose} severity={theme}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarAlert;
