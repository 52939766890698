import React from 'react';
import { Box, Collapse, Typography } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';

type DisplayAlertProps = {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  theme: Color;
};

const DisplayAlert = (props: DisplayAlertProps): JSX.Element => {
  const { message, setMessage, theme } = props;

  return (
    <Collapse in={message !== ''}>
      <Box pt={3}>
        <Alert severity={theme} onClose={() => setMessage('')}>
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {message}
          </Typography>
        </Alert>
      </Box>
    </Collapse>
  );
};

export default DisplayAlert;
