// 引数で渡されたテキストをクリップボードにコピーする
export const copyToClipboard = (copyText: string): void => {
  // コピーのイベントリスナーを追加
  document.addEventListener(
    'copy',
    (clipboardEvent) => {
      clipboardEvent.preventDefault();
      clipboardEvent.clipboardData?.setData('text/plain', copyText);
    },
    { once: true }
  );

  // 追加したコピーのイベントを発火
  document.execCommand('copy');
};

// アクセスしているブラウザの文字列を返す
export const getUsedBrowserName = (): string => {
  const userAgent = window.navigator.userAgent.toLowerCase();

  if (userAgent.indexOf('msie') !== -1) {
    return 'IE';
  } else if (userAgent.indexOf('edge') !== -1) {
    return 'Edge';
  } else if (userAgent.indexOf('chrome') !== -1) {
    return 'Chrome';
  } else if (userAgent.indexOf('safari') !== -1) {
    return 'Safari';
  } else if (userAgent.indexOf('firefox') !== -1) {
    return 'Firefox';
  } else {
    return 'Unknown';
  }
};

// モバイル端末かどうかを返却する(タブレット含む)
export const isMobile = (): boolean => {
  return !!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i);
};

// ランダムな文字列を生成する
const el = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_';
export const generateRandomStr = (count: number): string => {
  return Array.from(Array(count))
    .map(() => el[Math.floor(Math.random() * el.length)])
    .join('');
};
