import firebase from 'firebase/app';

// INFO: Firebaseに関する型定義はentries内で定義
export interface Schedule {
  id?: string;
  title: string;
  preview: string;
  fontColor: string;
  bgColor: string;
  editPassword?: string;
  downloadImageUrl?: string;
}

// firebaseの値からオブジェクトをbuildする
export const buildSchedule = (snapshot: firebase.firestore.DocumentData): Schedule => {
  // INFO: 複数データで渡ってきた場合は、最初の1つ目のみbuildする。
  const data = snapshot.docs ? snapshot.docs[0].data() : snapshot.data();

  return {
    id: snapshot.docs ? snapshot.docs[0].id : snapshot.id,
    title: data.title,
    preview: data.preview,
    fontColor: data.fontColor,
    bgColor: data.bgColor,
    editPassword: data.editPassword,
    downloadImageUrl: data.downloadImageUrl,
  } as Schedule;
};

// @ref: https://qiita.com/kousaku-maron/items/3d0c6ea850f1204f9a7d
