import firebase, { db } from '@/config/firebase';
import { buildSchedule, Schedule } from '@/entries/schedule';

// IdからScheduleを取得する
export const getScheduleById = async (docId: string): Promise<Schedule | null> => {
  try {
    const snapshot = await db.collection('schedules').doc(docId).get();
    return buildSchedule(snapshot);
  } catch (e) {
    console.warn(e);
    return null;
  }
};

// editPasswordからScheduleを取得する
export const getScheduleByPassword = async (password: string): Promise<Schedule | null> => {
  try {
    const snapshot = await db.collection('schedules').where('editPassword', '==', password).get();
    return buildSchedule(snapshot);
  } catch (e) {
    console.warn(e);
    return null;
  }
};

// Scheduleのオブジェクトをfirestoreに保存する
// INFO: setの戻り値が存在しないので、代わりに当関数内で作成したdocIdのみを返す。
export const createSchedule = async (schedule: Schedule): Promise<string> => {
  const docId = db.collection('schedules').doc().id;

  await db
    .collection('schedules')
    .doc(docId)
    .set({
      title: schedule.title,
      preview: schedule.preview,
      editPassword: schedule.editPassword,
      fontColor: schedule.fontColor,
      bgColor: schedule.bgColor,
      downloadImageUrl: schedule.downloadImageUrl,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      expiresAt: firebase.firestore.Timestamp.fromDate(getExpiresAt()),
    });

  return docId;
};

// docIdのDocをScheduleのオブジェクトで更新する
export const updateSchedule = async (docId: string, schedule: Schedule): Promise<void> => {
  const docRef = await db.collection('schedules').doc(docId);

  // 各値を更新する
  await docRef.update({
    title: schedule.title,
    preview: schedule.preview,
    editPassword: schedule.editPassword,
    fontColor: schedule.fontColor,
    bgColor: schedule.bgColor,
    downloadImageUrl: schedule.downloadImageUrl,
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    expiresAt: firebase.firestore.Timestamp.fromDate(getExpiresAt()),
  });
};

// 現在時刻から期限を取得するメソッド
// INFO: firebaseに関する共通メソッドなので、ここに置いておく。
const getExpiresAt = (): Date => {
  const expiresAt = new Date();
  // TODO: 有効期限は1年とする。
  //   現状はFirestore側で何も処理を行っていないので、実質無制限。
  expiresAt.setFullYear(expiresAt.getFullYear() + 1);
  return expiresAt;
};

// @ref: https://qiita.com/kousaku-maron/items/3d0c6ea850f1204f9a7d
