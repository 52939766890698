import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: 'white',
  },
}));

const Header = (): JSX.Element => {
  const classes = useStyles();

  return (
    <header>
      <AppBar position="fixed">
        <Box textAlign="center" py={1}>
          <Link to="/" className={classes.link}>
            <Typography variant="h6">Schedio</Typography>
          </Link>
        </Box>
      </AppBar>
    </header>
  );
};

export default Header;
