import React from 'react';
import Header from '@/components/molecules/Header';
import { Route, Switch } from 'react-router-dom';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import Home from '@/components/pages/Home';
import Shared from '@/components/pages/Shared';
import Footer from '@/components/molecules/Footer';
import Preview from '@/components/pages/Preview';
import Challenge from '@/components/pages/Challenge';
import Edit from '@/components/pages/Edit';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(3),
    },
  })
);

const Default = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Header />
      <main className={classes.content}>
        <Box pt={2}>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/preview" component={Preview} />
            <Route exact path="/edit/challenge" component={Challenge} />
            <Route path="/edit/:uid" component={Edit} />
            <Route path="/shared/:uid" component={Shared} />
          </Switch>
        </Box>
      </main>
      <Footer />
    </div>
  );
};

export default Default;
