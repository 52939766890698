import React, { useState } from 'react';
import { ColorResult, GithubPicker } from 'react-color';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PALLET_COLORS } from '@/constants/index';

type StyleProps = {
  color: string;
};

type ColorPickerProps = {
  label?: string;
  color: string;
  width?: string;
  setColor: React.Dispatch<React.SetStateAction<string>>;
};

const useStyles = makeStyles<Theme, StyleProps>(() =>
  createStyles({
    label: {
      fontWeight: 'bold',
    },
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
      backgroundColor: ({ color }) => color,
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popover: {
      position: 'absolute',
      zIndex: 2,
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  })
);

const ColorPicker = ({ color, setColor, label, width }: ColorPickerProps): JSX.Element => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false);
  const classes = useStyles({ color });

  const handleClick = () => setDisplayColorPicker(!displayColorPicker);
  const handleClose = () => setDisplayColorPicker(false);
  const handleChange = (color: ColorResult) => setColor(color.hex);

  return (
    <Box display="flex" alignItems="center" pt={0.5}>
      <Typography variant="subtitle2" className={classes.label} display="inline">
        {label}
      </Typography>
      <Box className={classes.swatch} onClick={handleClick} ml={0.8}>
        <Box className={classes.color} />
      </Box>
      {displayColorPicker ? (
        <Box className={classes.popover}>
          <Box className={classes.cover} onClick={handleClose} />
          <GithubPicker
            onChange={handleChange}
            width={width ? width : '120px'}
            triangle="hide"
            colors={PALLET_COLORS}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default ColorPicker;

// @ref: https://casesandberg.github.io/react-color/#examples
