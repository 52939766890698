import React, { ChangeEvent, useEffect, useState } from 'react';
import { StaticContext } from 'react-router';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { FileCopy } from '@material-ui/icons';
import TwitterIcon from '@material-ui/icons/Twitter';
import ColorPicker from '@/components/molecules/ColorPicker';
import { copyToClipboard, generateRandomStr } from '@/utils/index';
import ScheduleDisplay from '@/components/molecules/ScheduleDisplay';
import DisplayAlert from '@/components/molecules/DisplayAlert';
import { createSchedule, getScheduleById, updateSchedule } from '@/repositories/schedule';
import { Schedule } from '@/entries/schedule';
import { uploadImage } from '@/repositories/image';

interface RouteProps {
  title: string;
  preview: string;
  docId?: string; // INFO: 更新時処理の場合はpropsとしてdocIdを渡す。
}

const Preview = (props: RouteComponentProps<Record<string, never>, StaticContext, RouteProps>): JSX.Element => {
  const routeProps = props.location.state;

  // value save to firestore
  const [title, setTitle] = useState<string>('');
  const [preview, setPreview] = useState<string>('');
  const [fontColor, setFontColor] = useState<string>('#000');
  const [bgColor, setBgColor] = useState<string>('#FFF');

  // other constants
  const history = useHistory();
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<File>(null!);
  const [fileName, setFileName] = useState<string>('');
  const [documentId, setDocumentId] = useState<string>(routeProps.docId ? routeProps.docId : '');
  const [shareUrl, setShareUrl] = useState<string>(
    documentId !== '' ? `${process.env.REACT_APP_BASE_URL}/shared/${documentId}` : ''
  );
  const [editPassword, setEditPassword] = useState<string>('');
  const [msg, setMsg] = useState<string>('');

  /*
    On Mounted
   */
  useEffect(() => {
    // routeからPropsが渡ってこなかった場合、Home画面にPush
    if (!routeProps) {
      return history.push('/');
    } else {
      // INFO: title, previewは前画面での変更を反映させたいので、firestoreからの値はセットしない
      setTitle(routeProps.title);
      setPreview(routeProps.preview);
      // Passwordは編集毎に生成
      setEditPassword(generateRandomStr(16));

      // docIdが存在する場合はfirestoreからbgColor, fontColor, downloadImageUrlを取得
      if (routeProps.docId) {
        (async () => {
          setIsLoading(true);
          const schedule = await getScheduleById(String(routeProps.docId));

          if (schedule) {
            // firestoreから取得したデータをstateに格納
            setBgColor(schedule.bgColor);
            setFontColor(schedule.fontColor);
            setPreviewUrl(String(schedule.downloadImageUrl));

            setIsLoading(false);
          } else {
            // メモリリーク防止のため、ページジャンプする前にfalseに設定
            setIsLoading(false);
            alert('スケジュール情報が存在しません。');
            history.push('/');
          }
        })();
      }
    }
  }, [history, routeProps]);

  /*
    Handlers
   */
  // 'コピー'ボタンをクリックした際の処理
  const handleCopyClick = () => {
    const copyText = `【タイトル】\n${title}\n【スケジュール】\n${preview}`;
    copyToClipboard(copyText);
    setMsg('テキストをコピーしました。');
  };

  // 編集用パスワードのアイコンをクリックした際の処理
  const handleCopyIconClick = () => {
    copyToClipboard(editPassword);
    setMsg('パスワードをコピーしました。');
  };

  // 'URLでシェア'ボタンをクリックした際の処理
  const handleShareToUrlClick = async () => {
    const url = await saveDocument();
    console.log();
    copyToClipboard(url !== '' ? url : shareUrl);
    setMsg('URLをコピーしました。');
  };

  // 'ツイート'ボタン押下時の処理
  const handleShareToTwitter = async () => {
    const url = await saveDocument();
    window.open(
      `https://twitter.com/share?url=${shareUrl !== '' ? shareUrl : url}&text=${title}`,
      '_blank',
      'noreferrer'
    );
  };

  // '画像アップロード'ボタン押下時の処理
  const handleClickUploadButton = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    // 画像が選択された場合の処理
    if (e.target.files !== null) {
      const selectedImage = e.target.files[0];
      if (selectedImage) {
        setImage(selectedImage);
        setFileName(new Date().toISOString() + selectedImage.name);
        setPreviewUrl(window.URL.createObjectURL(selectedImage));
      }
    } else return;
  };

  /*
    Functions
   */
  // 新規作成時：Firestoreにデータを保存し、URLを返す。
  // 更新時(既にdocIdをstateで管理している場合)：更新処理を行い、空文字を返す。
  const saveDocument = async () => {
    setIsLoading(true);

    // 画像を保存する
    // INFO: docIdが存在しかつ画像を変更をしていない場合は、
    //  既に登録済みの画像URLがpreviewUrlに入っているので、previewUrlをdownloadImageUrlに格納する。
    let downloadImageUrl = documentId ? previewUrl : '';
    if (image) downloadImageUrl = await uploadImage(fileName, image);

    // 変更・更新用のオブジェクトを組み立てる
    const preSchedule: Schedule = {
      title,
      preview,
      editPassword,
      fontColor,
      bgColor,
      downloadImageUrl,
    };

    if (documentId === '') {
      // 新規作成処理
      const docId = await createSchedule(preSchedule);

      const url = `${process.env.REACT_APP_BASE_URL}/shared/${docId}`;
      setDocumentId(docId);
      setShareUrl(url);
      setIsLoading(false);

      return url;
    } else {
      // 更新処理
      await updateSchedule(documentId, preSchedule);

      setIsLoading(false);
      return '';
    }
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 100, color: '#fff' }}>
        <CircularProgress />
      </Backdrop>
      {/* Alert */}
      <DisplayAlert message={msg} setMessage={setMsg} theme="success" />
      <Box pt={3}>
        {/* Preview */}
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            プレビュー
          </Typography>
          <ScheduleDisplay
            title={title}
            preview={preview}
            fontColor={fontColor}
            bgColor={bgColor}
            imageUrl={previewUrl}
          />
        </Box>
        {/* Select color */}
        <Box py={0.5}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <ColorPicker color={fontColor} setColor={setFontColor} label="フォントカラー" />
            </Grid>
            <Grid item>
              <ColorPicker color={bgColor} setColor={setBgColor} label="背景色" />
            </Grid>
          </Grid>
        </Box>
        {/* Upload image */}
        <Box textAlign="center" py={1}>
          <Button component="label" variant="contained" color="primary" fullWidth>
            画像アップロード
            <input type="file" name="image" style={{ display: 'none' }} onChange={handleClickUploadButton} />
          </Button>
        </Box>
        {/* Editable password */}
        <Box pt={1} pb={2}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            <Box display="flex" alignItems="center">
              編集用パスワード
              <IconButton onClick={handleCopyIconClick}>
                <FileCopy fontSize="small" color="primary" />
              </IconButton>
            </Box>
          </Typography>
          <Box px={5} pt={0.5}>
            <Card style={{ backgroundColor: '#D3D3D3' }}>
              <Box display="flex" justifyContent="center" py={0.5}>
                <Typography variant="subtitle2">{editPassword}</Typography>
              </Box>
            </Card>
          </Box>
          <Box pt={1.5}>
            <Typography variant="body2" color="textSecondary">
              このパスワードは編集時に必要となります。
              <br />
              紛失時の再発行などはできませんので、大切に保管してください。
            </Typography>
          </Box>
        </Box>
        <Divider light />
        {/* Action buttons */}
        <Box display="flex" pb={2} pt={3}>
          <Button variant="contained" color="primary" fullWidth size="medium" onClick={handleCopyClick}>
            コピー
          </Button>
          <Box m={1} />
          <Button variant="contained" color="primary" fullWidth size="medium" onClick={handleShareToUrlClick}>
            URLでシェア
          </Button>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            size="medium"
            style={{
              backgroundColor: '#00acee',
              color: '#FFF',
            }}
            startIcon={<TwitterIcon />}
            onClick={handleShareToTwitter}
          >
            Tweet
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Preview;
