import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';

interface ScheduleDisplayProps {
  title: string;
  preview: string;
  fontColor: string;
  bgColor: string;
  imageUrl?: string;
}

const ScheduleDisplay = (props: ScheduleDisplayProps): JSX.Element => {
  const { title, preview, fontColor, bgColor, imageUrl } = props;

  return (
    <Box
      py={1}
      px={1}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
      }}
    >
      <Card style={{ opacity: imageUrl ? 0.85 : 1 }}>
        <Box py={2} px={2} style={{ color: fontColor, backgroundColor: bgColor }}>
          <Box pb={1}>
            <Typography variant="subtitle1">{title}</Typography>
          </Box>
          <Box pb={1}>
            <Typography variant="subtitle2" style={{ whiteSpace: 'pre-line' }}>
              {preview}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default ScheduleDisplay;
